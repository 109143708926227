import { readonly, ref } from "vue";
import type { ISidebarLink } from "@/types";



const links = ref<ISidebarLink[]>([
    {
        name: "Dashboard",
        icon: 'HomeIcon',
        nameRoute: "Home",
    },
    {
        name: "Agentes",
        icon: 'GraphIcon',
        nameRoute: "Agentes",
    },
    // {
    //     name: "Quadrante",
    //     icon: 'CategoryIcon',
    //     nameRoute: "Quadrante",
    // },
    {
        name: "Financeiro",
        icon: 'ChartIcon',
        nameRoute: "Financeiro",
    },
    {
        name: "Financeiro",
        icon: 'ChartIcon',
        nameRoute: "Financeiro",
    },
    {
        name: "Usuários",
        icon: 'ProfileIcon',
        nameRoute: "Usuários",
    },
    {
        name: "Configurações",
        icon: 'SettingIcon',
        nameRoute: "UsuáConfiguraçõesrios",
        children: [
            {
                name: "Grupos",
                icon: '',
                nameRoute: "grupos",
            },
            {
                name: "Empresas",
                icon: '',
                nameRoute: "empresas",
            },
            {
                name: "Unidades",
                icon: '',
                nameRoute: "Unidades",
            },
            {
                name: "Tipos de Equipamentos",
                icon: '',
                nameRoute: "tipo-equipamentos",
            },
            {
                name: "Equipamentos",
                icon: '',
                nameRoute: "equipamentos",
            },
            {
                name: "Provedor",
                icon: '',
                nameRoute: "provedor",
            },
            {
                name: "Link de Internet",
                icon: '',
                nameRoute: "link-internet",
            },
            {
                name: "Serviços",
                icon: '',
                nameRoute: "servicos",
            },
            {
                name: "Coletores",
                icon: '',
                nameRoute: "coletores",
            }
        ]
    },

]);

const showNavigation = ref<boolean>(window.innerWidth > 768)



export default () => {
    return {
        links: readonly(links),
        showNavigation
    };
};
