import { reactive, computed } from "vue";



const state = reactive<{ loading: boolean }>({
  loading: false,
});


const actions = {
  ActionSetPreloader(payload: boolean) {
    state.loading = payload;
  }


};
const getters = {
  isLoading: computed(() => state.loading),
};
export { getters, state, actions };
