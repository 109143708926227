import { ref, computed } from "vue";

const state = ref<string>("");

function ActionSetUser(theme: string) {
  state.value = theme;
}

const actions = {
  setTheme: async (payload: any) => {
    ActionSetUser(payload);
  },
};
const getters = {
  getTheme: computed(() => {
    const themeState = state.value;
    let themeSelected = 'prime'
    if (themeState.includes('goahead')) {
      themeSelected = 'goahead';
    }
    const themeLength = Number(themeSelected.length);
    const themeIndexof = Number(themeState.indexOf(themeSelected));
    const endSlice = themeLength + themeIndexof;
    return themeState.slice(themeIndexof, endSlice);
  }),
};
export { getters, state, actions };
