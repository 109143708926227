import { ref, computed } from "vue";
import { changePassword, getUserId, login, loginV2, resetPassword, resetPasswordV2 } from "@/core/service/AuthService";

import * as storage from "./storage"
import { Credential } from "@/core/model";
import swal from 'sweetalert2';
import { LoginResponse } from "@/core/model/LoginResponse";
let token = localStorage.getItem("token");

if (token) token = JSON.parse(String(localStorage.getItem(token)));

const state = ref<LoginResponse>({} as LoginResponse);

function ActionSetUser(payload: LoginResponse) {
  state.value = payload;
  storage.setLocalUsuario(payload)
  storage.setLocalToken(payload.data.access_token);
}


const actions = {
  login: async (credentials: Credential) => {
    try {
      const user = await login(credentials);
      ActionSetUser(user);

    } catch (error: any) {
      const message = error?.response?.data?.message;
      if (message == 'invalid_credentials')
        swal.fire({
          icon: "warning",
          text: "Usuário sem acesso ao sistema",
          title: "Atenção"
        });
      throw new Error("Usuário sem acesso ao sistema")
    }
  },
  loginV2: async (credentials: Credential) => {
    try {
      const loginResponse = await loginV2(credentials);
      const userDetailsResponse = await getUserId(loginResponse.data.payload.id, loginResponse.data.access_token!)

      const user = {
        ...loginResponse,
        details: userDetailsResponse
      }

      ActionSetUser(user);
      return user;

    } catch (error: any) {
      const message = error?.response?.data?.message;
      if (message == 'invalid_credentials') {
        swal.fire({
          icon: "warning",
          text: "Usuário sem acesso ao sistema",
          title: "Atenção"
        });
      }
      throw new Error("Usuário sem acesso ao sistema")
    }
  },
  resetPassword: async (email: string) => {
    try {
      await resetPassword(email)
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  },
  resetPasswordV2: async (email: string) => {
    try {
      await resetPasswordV2(email)
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  },
  changePassword: async (password: string) => {
    try {
      await changePassword(password)
    } catch (error) {
      throw new Error(JSON.stringify(error))
    }
  },
  setUser: async (payload: any) => {
    ActionSetUser(payload);
  },
  logout: () => {
    state.value = {} as LoginResponse;
    storage.deleteLocalToken();
    storage.deleteLocalUsuario();
  },

  // forgotPwd: async (email: string) => {
  //   const data = await resetPassword(email);
  //   return data;
  // },

  ActionCheckToken() {
    try {
      const token = storage.getLocalToken();
      if (state.value.token) {
        return Promise.resolve(state.value.token);
      } else if (!token) {
        return this.logout()
      } else {
        ActionSetUser(storage.getLocalUsuario()); ''
        return Promise.resolve(token);
      }
    } catch (error) {
      actions.logout();
    }

  },
};
const getters = {
  getStatusLoggedIn: computed(() => {
    return !!(state.value && state.value.token)
  }),
  getToken: computed(() => {
    return state.value?.data?.access_token;
  }),
  getUser: computed(() => {
    return state.value;
  }),
  getRoles: computed(() => {
    return (state?.value?.data?.payload?.role ?? []);
  }),
  isMaster: computed(() => {
    return state.value.user?.is_master == 1 ?? false;
  }),
  isAdmin: computed(() => {
    return state.value.user?.is_admin == 1 ?? false;
  }),
  // isManager: computed(() => {
  //   return (state.value.roles ?? []).filter(f => f.name.toLowerCase() == 'mk' && f.data.role.toLowerCase().includes('Manager'.toLowerCase())).length > 0;
  // }),
  // isCollaborator: computed(() => {
  //   return (state.value.roles ?? []).filter(f => f.name.toLowerCase() == 'mk' && f.data.role.toLowerCase().includes('collaborator'.toLowerCase())).length > 0;
  // })
};
export { getters, state, actions };
