import { AxiosResponse } from 'axios'
import { http } from '../api'

import { Credential } from '../model'
import { LoginResponse, UserDetailsResponse } from '../model/LoginResponse';





export async function login(payload: Credential): Promise<LoginResponse> {
  const controller = 'v1/auth/login'

  const { data } = await http.get<LoginResponse, AxiosResponse>(`${controller}`, {
    auth: {
      username: payload.email,
      password: payload.password
    }
  });
  return data;
}

export async function loginV2(payload: Credential): Promise<LoginResponse> {
  const controller = 'v2/auth/login'

  console.log("payload", payload);

  const { data } = await http.post<LoginResponse, AxiosResponse>(`${controller}`, null, {
    auth: {
      username: payload.email,
      password: payload.password
    }
  });
  return data;
}

export async function updateUserV2(payload: any, userId: string): Promise<LoginResponse> {
  const controller = `v2/user/${userId}`
  const { data } = await http.put<LoginResponse, AxiosResponse>(controller, payload);
  return data;
}

export async function getUserId(userId: string, token: string): Promise<UserDetailsResponse> {
  const controller = `v2/user/${userId}`

  const { data } = await http.get<UserDetailsResponse, AxiosResponse>(controller, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return data;
}

export async function resetPassword(email: string) {
  const controller = 'v1/auth/reset_password'
  await http.post<AxiosResponse>(`${controller}`, {
    username: email
  });;
}

export async function resetPasswordV2(email: string) {
  const controller = 'v2/auth/reset_password'
  await http.post<AxiosResponse>(`${controller}`, {
    email: email
  });;
}

export async function changePassword(password: string) {
  const controller = 'v1/auth/update_password'
  await http.post<AxiosResponse>(`${controller}`, {
    password
  });;
}

