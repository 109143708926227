import { Authentication } from "@/store";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../pages/login/loginPage.vue'),
  },
  {
    path: '/login-cliente',
    name: 'Login Cliente',
    component: () => import('../pages/login/loginCliente.vue'),
  },
  {
    path: '/forgot-password',
    name: 'Esqueceu a senha',
    component: () => import('../pages/login/forgotPasswordPage.vue'),
  },
  // {
  //   path: '/reset-password',
  //   name: 'Redefina sua senha',
  //   component: () => import('../pages/login/resetPasswordPage.vue'),
  // },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../pages/dashboard/DashboardPage.vue'),
    meta: {
      master: 'Página principal',
      admin: 'Página principal',
      collaborator: 'Página principal',
      manager: 'Página principal',
      icon: 'HouseIcon',
      divider: true,
    },
  },
  {
    path: '/goal',
    name: 'Metas',
    component: () => import('../pages/goal/GoalPage.vue'),
    meta: {
      master: 'Empresas',
      admin: 'Matriz / Filiais',
      collaborator: 'Minha empresa',
      manager: 'Matriz / Filiais',
      icon: 'CompanyIcon',
    },
  },
  {
    path: '/grades',
    name: 'Notas',
    component: () => import('../pages/grades/GradesPage.vue'),
    meta: {
      master: 'Usuários',
      admin: 'Usuários',
      manager: 'Usuários',
      icon: 'PeopleIcon',
    },
  },
  {
    path: '/collaborators',
    name: 'Colaboradores',
    component: () => import('../pages/collaborators/CollaboratorsPage.vue'),
    meta: {
      master: 'Grupos',
      admin: 'Grupos',
      manager: 'Grupos',
      collaborator: 'Meus Grupos',
      icon: 'GroupIcon',
    },
  },
  {
    path: '/settings',
    name: 'Configurações',
    component: () => import('../pages/settings/SettingsPage.vue'),
    meta: {
      master: 'Dispositivos',
      admin: 'Dispositivos',
      manager: 'Dispositivos',
      collaborator: 'Dispositivos',
      icon: 'MonitorMobbileIcon',
    },
  },

  {
    path: '/profile',
    name: 'Meus Dados',
    component: () => import('../pages/profile/ProfileData.vue'),
    meta: {
      master: 'Meus dados',
      admin: 'Meus dados',
      manager: 'Meus dados',
      collaborator: 'Meus dados',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  Authentication.actions.ActionCheckToken();
  const token = Authentication.getters.getToken.value;
  const roles = Authentication.getters.getRoles.value;

  if (to.name == 'Esqueceu a senha') {
    next(true);
  }

  if (!token && to.name !== 'Login') {
    next({ name: 'Login' });
  } else if (token && to.path == "/") {
    next({
      name: "Dashboard"
    })
  } else if (to.path != '/dashboard' && to.path != '/' && to.path != '/profile') {
    if (token && roles && roles.find((f) => to.path.includes(f))) {
      next(true);
    } else {
      next({
        name: "Dashboard"
      });
    }
  }
  else next(true);
});
export { routes };
export default router;
