// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

import { md1 } from 'vuetify/blueprints'

const prime = {
    colors: {
        primary: "#006EE2"
    }
}

const goAhead = {
    colors: {
        primary: "#FF5C00"
    }
}

const defaultTheme = {
    colors: {
        primary: "#006EE2"
    }
}

const myCustomLightTheme = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        surface: '#FFFFFF',
        primary: '#17CF97',
        'primary-darken-1': '#3700B3',
        secondary: '#006EE2',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        'gray-500': '#697B8E',
        warning: '#FB8C00',
    },
};

export default createVuetify({
    blueprint: md1,
    // theme: {
    //     defaultTheme: 'myCustomLightTheme',
    //     themes: {
    //         myCustomLightTheme,
    //     }
    // }
})


